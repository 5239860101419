import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/interna',
    name: 'Interna',
    component: () => import(/* webpackChunkName: "about" */ '../views/Inter.vue')
  },
  {
    path: '/nosotros',
    name: 'nosotros',
    component: () => import(/* webpackChunkName: "about" */ '../views/nosotros.vue')
  },
  {
    path: '/nuestros-servicios',
    name: 'nuestros-servicios',
    component: () => import(/* webpackChunkName: "about" */ '../views/nuestros-servicios.vue')
  },
  {
    path: '/contacto',
    name: 'contacto',
    component: () => import(/* webpackChunkName: "about" */ '../views/contacto.vue')
  },
  {
    path: '/cotizar',
    name: 'cotizar',
    component: () => import(/* webpackChunkName: "about" */ '../views/cotizar.vue')
  },
  {
    path: '/unete-a-nuestro-equipo',
    name: 'unete-a-nuestro-equipo',
    component: () => import(/* webpackChunkName: "about" */ '../views/unete-a-nuestro-equipo.vue')
  },

  /*LANDING PAGE*/
  {
    path: '/promocion',
    name: 'promo',
    component: () => import(/* webpackChunkName: "about" */ '../views/promo.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
