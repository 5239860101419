<template>
<div>
<div class="menu__principal cc">
<div class="menu__principal_hijo bc">
	<router-link to="/" class="logo__principal sc">
		{{logo}}
	</router-link>



  <div class="menu__links hideOnMovil sc">
    <router-link to="/" class="menu__link hideOnMovil cc">
      Nosotros
    </router-link>

    <router-link to="/nuestros-servicios" class="menu__link hideOnMovil cc">
      Nuestros servicios
    </router-link>
    
    
    <router-link to="/contacto" class="menu__link hideOnMovil cc">
      Contacto
    </router-link>

    <router-link to="/unete-a-nuestro-equipo" class="menu__link hideOnMovil cc">
      Únete a nuestro equipo
    </router-link>
    <v-btn
    to="/cotizar"
    style="color: white;margin-left: 15px;"
    large rounded
    color="#00A4B8">
      Cotizar trabajo
    </v-btn>
  </div>
	<v-btn

	class="showOnMovil"
	color="transparent"
  style="color: white !important;"
  fab small flat depressed
  @click.stop="drawer = !drawer"
  >
    <v-icon dark>
      mdi-menu
    </v-icon>
  </v-btn>
</div>
</div>

	<!--NAVIGATION DRAWER-->
	<v-navigation-drawer
      style="z-index: 1000000;"
			right
      v-model="drawer"
      absolute
      temporary
    >
      <v-list-item>
      	<div class=" sc">
					{{logo}}
				</div>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          link
          @click="redirigir('Home')"
        >
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Nosotros
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          @click="redirigir('nuestros-servicios')"
        >
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Nuestros servicios
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          @click="redirigir('contacto')"
        >
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Contacto
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          @click="redirigir('unete-a-nuestro-equipo')"
        >
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Únete a nuestro equipo
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          @click="redirigir('cotizar')"
        >
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Cotizar trabajo
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>



      </v-list>
  </v-navigation-drawer>
</div>
</template>

<script>
import router from '../router/index.js'
  export default {
    data () {
      return {
      	logo:'Traducciones FOCUS',
        drawer: null,
        items: [
          { title: 'Home', icon: 'mdi-view-dashboard' },
          { title: 'About', icon: 'mdi-forum' },
        ],
      }
    },
    methods:{
      redirigir(ruta){
        router.push({ 
          name: ruta
        });
      },
    }
  }
</script>

<style>
.menu__principal{
	width: 100%; height: 35px; 
  top: 20px;position: absolute;
  z-index: 3000;
}
.menu__principal_hijo{width: 95%; height: 100%;}
	.logo__principal{
		width: 25%; height: 100%;
		font-weight: 600;font-size: 25px;
    color: var(--colorEnlaces);
    text-decoration: none;color: white!important;
	} 

  .menu__links{height: 100%;}
  .menu__link{
    height: 100%;
    margin-left: 15px; 
    text-decoration: none;
    font-family: 'poppins'!important;
    font-size: 16px;
    color: var(--colorEnlaces) !important;
    border-bottom: solid 2px transparent;
    transition: .7s;
  }
  .menu__link:hover{
    border-bottom: solid 2px white;
    transition: .7s;
  }
@media(max-width: 900px)
{
	.menu__principal{width: 90%; margin-left: 5%;}
	.logo__principal{width: 60%;font-size: 16px;}
}
</style>