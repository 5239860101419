<template>
  <div class="home">
    
    <MenuPrincipal />
    <HeaderMain/>
    <Services />
    <Block1/>
    <Block2/>
  </div>
</template>

<script>
// @ is an alias to /src
import MenuPrincipal from '@/components/MenuPrincipal.vue'
import HeaderMain from '@/components/HeaderMain.vue'
import Services from '@/components/Services.vue'
import Block1 from '@/components/Block1.vue'
import Block2 from '@/components/Block2.vue'

export default {
  name: 'Home',
  components: {
    MenuPrincipal,
    HeaderMain,
    Services,
    Block1,
    Block2
  }
}
</script>
